<template>
  <div>
    <label class="col-form-label col-form-label--accent" v-if="title">{{ title }}</label>
    <div>
      <b-form-row>
        <b-col md="6" lg="4">
          <field-nations
            :read-only="readOnly"
            title="Nazione"
            :fieldId="ids.countryCode"
            v-model="countryCode"
            :state="state.countryCode"
          ></field-nations>
        </b-col>
        <b-col md="6" lg="6">
          <field-text
            :disabled="readOnly"
            :fieldId="ids.city"
            placeholder="Città"
            title="Città"
            v-model="city"
            :state="state.city"
          />
        </b-col>
        <b-col md="6" lg="2">
          <field-text
            :disabled="readOnly"
            :fieldId="ids.province"
            placeholder="Provincia"
            title="Provincia"
            v-model="province"
            :state="state.province"
          />
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
const FieldText = () => import('@/components/fields/FieldText.vue');
const FieldNations = () => import('@/components/fields/FieldNations.vue');

export default {
  name: 'FieldCity',
  components: {
    FieldText,
    FieldNations,
  },
  model: {
    prop: 'address',
    event: 'input',
  },
  props: {
    fieldId: String,
    address: Object,
    title: String,
    state: Object,
    errorMessage: String,
    readOnly: Boolean,
  },
  computed: {
    failed() {
      return this.errorMessage || 'Il campo è obbligatorio';
    },
    ids() {
      return {
        countryCode: `${this.fieldId}.countryCode`,
        city: `${this.fieldId}.city`,
        province: `${this.fieldId}.province`,
      };
    },
    countryCode: {
      get() {
        return this.address.countryCode;
      },
      set(countryCode) {
        // console.log('updateSelf', countryCode);
        this.$emit('input', { ...this.address, countryCode });
      },
    },
    city: {
      get() {
        return this.address.city;
      },
      set(city) {
        // console.log('updateSelf', city);
        this.$emit('input', { ...this.address, city });
      },
    },
    province: {
      get() {
        return this.address.province;
      },
      set(province) {
        // console.log('updateSelf', province);
        this.$emit('input', { ...this.address, province });
      },
    },
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
